import { checkValue } from 'components/functions';
import React from 'react';
import { UitkHeading } from 'uitk-react-text';
import { useStores } from 'utils/useStore';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { RenderImage } from 'src/components/utils/responsive-image';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from 'src/utils/localization';
var GoGuidesAlsoPopularComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var loc = new localization();
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: 'also popular'
    }
  };
  var contentData = goGuidesStore.fetchAlsoPopular(goGuidesStore.pageUrlData);
  var items = [];
  var parentName = '';
  if (checkValue(contentData)) {
    var parentDestinationName = contentData.parentDestinationName,
      destinationData = contentData.destinationData;
    items = checkValue(destinationData) ? destinationData : [];
    parentName = checkValue(parentDestinationName) ? parentDestinationName : '';
  }
  var bucketHeader = parentName === '' ? loc.getLocalizedText('also_popular') : loc.getLocalizedText('also_popular_in', parentName);
  var CardItem = function (_a) {
    var _b;
    var item = _a.item;
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=400&height=267',
          large: '&width=600&height=400'
        }
      }
    };
    return React.createElement("li", {
      className: "item-wrap col-6 col-m3"
    }, React.createElement(UitkCard, null, React.createElement(UitkFigure, {
      className: "img-wrap",
      ratio: UitkFigureAspectRatioType.R16_9
    }, React.createElement("span", null, item.name), React.createElement(RenderImage, {
      imgProps: imageProps
    })), React.createElement(UitkCardLink, null, React.createElement("a", {
      href: item.targetUrl
    }, item.name))));
  };
  return React.createElement(React.Fragment, null, checkValue(items) && items.length > 0 && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement("section", {
    className: "other-hub sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 no-button"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement(UitkHeading, {
    size: 5,
    tag: "h2"
  }, bucketHeader)), React.createElement("div", {
    className: "item-list-page-wrap"
  }, React.createElement("ul", {
    className: "row item-list-container"
  }, items.map(function (item, index) {
    return React.createElement(CardItem, {
      key: index,
      item: item
    });
  }))))))));
};
export default GoGuidesAlsoPopularComponent;