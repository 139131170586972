import React from "react";
import { checkValue } from "components/functions";
import { UitkLink } from 'uitk-react-link';
import { UitkCard, UitkCardLink } from "uitk-react-cards";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { UitkHeading, UitkSubheading } from "uitk-react-text";
import { useStores } from "utils/useStore";
import CarouselComponent from "../generic/render-carousel";
import { RenderImage } from "src/components/utils/responsive-image";
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { localization } from "src/utils/localization";
var GoGuidesContributorsStoriesComponent = function () {
  var goGuidesStore = useStores().goGuidesStore;
  var contentData = goGuidesStore.fetchContributorsStories(goGuidesStore.pageUrlData);
  var loc = new localization();
  var moduleName = 'blog container';
  var impressionTrackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: moduleName
    }
  };
  var stories = [];
  var seeAllUrl = '';
  if (checkValue(contentData)) {
    var storiesData = contentData.storiesData;
    stories = checkValue(storiesData.storiesList) ? storiesData.storiesList : [];
    seeAllUrl = checkValue(storiesData.seeAllStoriesLink) ? storiesData.seeAllStoriesLink.targetUrl : '';
  }
  var headerText = loc.getLocalizedText('blog.stories_container');
  var seeAllLabel = loc.getLocalizedText('blog.see_all_stories');
  var blogLabel = loc.getLocalizedText('category.blogs');
  var carouselConfigProps = {
    carouselName: moduleName,
    carouselHeaderText: 'Blog Stories Carousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3
    },
    pageBy: 3,
    peek: true,
    experience: goGuidesStore.ClicksteamExperience
  };
  var CarouselItem = function (_a) {
    var _b;
    var item = _a.item;
    var imageProps = {
      image: (_b = item.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      alt: item.name,
      params: {
        size: {
          small: '&width=600&height=400',
          medium: '&width=600&height=400',
          large: '&width=800&height=533'
        }
      }
    };
    return React.createElement(UitkCard, null, React.createElement("div", {
      className: "item-wrap"
    }, React.createElement(UitkFigure, {
      className: "img-wrapper",
      ratio: UitkFigureAspectRatioType.R16_9
    }, React.createElement(RenderImage, {
      imgProps: imageProps
    }), React.createElement("span", {
      className: "red-tab"
    }, blogLabel)), React.createElement("div", {
      className: "detail-wrapper"
    }, React.createElement(UitkHeading, {
      tag: "h3",
      size: 7
    }, item.name)), React.createElement(UitkCardLink, null, React.createElement("a", {
      href: item.targetUrl
    }, item.name))));
  };
  var carouselData = stories.map(function (item, index) {
    return React.createElement(CarouselItem, {
      key: index,
      item: item
    });
  });
  return React.createElement(React.Fragment, null, checkValue(stories) && checkValue(stories.length > 0) && React.createElement(EgClickstreamImpressionTracker, {
    eventData: impressionTrackingObject
  }, React.createElement("section", {
    className: "blog-stories-container sec-control"
  }, React.createElement("div", {
    className: "container-wrap row"
  }, React.createElement("div", {
    className: "row-inner col-12 line-up"
  }, React.createElement("div", {
    className: "blog-stories-inner"
  }, React.createElement("div", {
    className: "section-header-wrapper"
  }, React.createElement("div", {
    className: "header-wrapper"
  }, React.createElement(UitkSubheading, {
    tag: "h2",
    className: ""
  }, headerText)), checkValue(seeAllUrl) && React.createElement("div", {
    className: "see-all-stories desktop"
  }, React.createElement(UitkLink, {
    className: "see-all-link"
  }, React.createElement("a", {
    href: seeAllUrl,
    target: "_blank",
    rel: "noreferrer"
  }, seeAllLabel)))), React.createElement(CarouselComponent, {
    data: carouselData,
    config: carouselConfigProps
  })))))));
};
export default GoGuidesContributorsStoriesComponent;