import React from 'react';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkHeading } from 'uitk-react-text';
var CardSizeM = function (props) {
  var data = props.data;
  var name = data.name,
    targetUrl = data.targetUrl,
    snippetText = data.snippetText,
    image = data.image,
    index = data.index;
  var imageProps = {
    image: image.imageUrl,
    alt: name,
    params: {
      size: {
        small: '&width=800&height=533',
        medium: '&width=1600&height=1066'
      }
    }
  };
  var loc = new localization();
  var buttonKey = loc.getLocalizedText('read_more');
  return React.createElement("li", {
    key: index,
    className: "col-12 col-m6"
  }, React.createElement(UitkCard, {
    className: 'item-list-wrapper'
  }, React.createElement(UitkFigure, {
    className: 'img-wrapper',
    ratio: UitkFigureAspectRatioType.R16_9
  }, React.createElement(RenderImage, {
    imgProps: imageProps
  })), React.createElement("div", {
    className: "detail-wrapper"
  }, React.createElement("div", {
    className: "header-wrap"
  }, React.createElement(UitkHeading, {
    tag: "h3"
  }, name)), React.createElement("p", {
    className: "text-wrap",
    dangerouslySetInnerHTML: {
      __html: snippetText
    }
  }), React.createElement(UitkPrimaryButton, {
    className: "teaser-read-more-btn",
    tag: "button",
    type: "button"
  }, buttonKey)), React.createElement(UitkCardLink, null, React.createElement("a", {
    href: targetUrl
  }, name))));
};
export default CardSizeM;