import React from 'react';
import { useStores } from 'utils/useStore';
import { checkValue } from 'components/functions';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkFigure, UitkFigureAspectRatioType } from 'uitk-react-images';
import { UitkSpacingHr } from 'uitk-react-spacing';
import { EgClickstreamImpressionTracker } from '@shared-ui/clickstream-analytics-context';
import { RenderImage } from 'src/components/utils/responsive-image';
import { localization } from 'src/utils/localization';
import { UitkCard, UitkCardLink } from 'uitk-react-cards';
var GoGuidesRelatedStoriesComponent = function (props) {
  var goGuidesStore = useStores().goGuidesStore;
  var loc = new localization();
  var contentData = goGuidesStore.fetchRelatedStories(goGuidesStore.pageUrlData);
  var seeAlso = props.seeAlso;
  var stories;
  var renderSeeAlso;
  if (checkValue(contentData)) {
    var relatedStoriesData = contentData.relatedStories,
      showSeeAlso = contentData.showSeeAlso;
    renderSeeAlso = checkValue(showSeeAlso) && checkValue(seeAlso);
    if (renderSeeAlso) {
      stories = relatedStoriesData.slice(0, 3);
    } else if (!checkValue(seeAlso)) {
      stories = relatedStoriesData;
    } else {
      stories = [];
    }
  }
  var trackingObject = {
    event: {
      event_name: 'go_guides_module.presented',
      event_type: 'Impression',
      event_version: '1.0.0',
      event_category: 'go_guides'
    },
    experience: goGuidesStore.ClicksteamExperience,
    module: {
      module_name: seeAlso ? 'see also' : 'related stories'
    }
  };
  var CardItem = function (_a) {
    var _b;
    var story = _a.story,
      _c = _a.seeAlso,
      seeAlso = _c === void 0 ? false : _c;
    var imageProps = {
      image: (_b = story.image) === null || _b === void 0 ? void 0 : _b.imageUrl,
      alt: story.name,
      params: {
        size: {
          small: '&width=400&height=267',
          medium: '&width=800&height=533',
          large: '&width=600&height=400'
        }
      }
    };
    return React.createElement("li", {
      className: "item-wrap ".concat(seeAlso ? 'col-12 col-m4' : 'col-6 col-m6 col-l3')
    }, React.createElement(UitkCard, {
      border: true
    }, React.createElement("div", {
      className: "item-wrap-inner"
    }, React.createElement(UitkFigure, {
      className: "img",
      ratio: UitkFigureAspectRatioType.R3_2
    }, React.createElement(RenderImage, {
      imgProps: imageProps
    }), story.isBlog && React.createElement(UitkText, {
      className: "red-tab"
    }, loc.getLocalizedText('category_blogs'))), React.createElement("div", {
      className: "detail-wrap"
    }, React.createElement(UitkHeading, {
      tag: "h3"
    }, story.name), React.createElement(UitkText, null, React.createElement(UitkIcon, {
      className: "icon",
      name: "place",
      size: UitkIconSize.EXTRA_SMALL
    }), story.countryName))), React.createElement(UitkCardLink, null, React.createElement("a", {
      href: story.targetUrl
    }, story.name))));
  };
  return React.createElement(React.Fragment, null, checkValue(stories) && stories.length > 0 && React.createElement(EgClickstreamImpressionTracker, {
    eventData: trackingObject
  }, renderSeeAlso ? React.createElement("section", {
    className: "related-content see-also"
  }, React.createElement(UitkHeading, {
    tag: "h4"
  }, loc.getLocalizedText('second_fold_related_stories.see_also')), React.createElement(UitkSpacingHr, {
    blockMargin: "three"
  }), React.createElement("ul", {
    className: "row"
  }, stories.map(function (story, index) {
    return React.createElement(CardItem, {
      key: index,
      story: story,
      seeAlso: true
    });
  }))) : React.createElement("section", {
    className: "related-content"
  }, React.createElement(UitkSpacingHr, {
    blockMargin: "eight"
  }), React.createElement(UitkHeading, {
    tag: "h2"
  }, loc.getLocalizedText('related_stories')), React.createElement("ul", {
    className: "row"
  }, stories.map(function (story, index) {
    return React.createElement(CardItem, {
      key: index,
      story: story
    });
  })))));
};
export default GoGuidesRelatedStoriesComponent;